import React from "react"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"

import PageHeader from "../components/chrome/pageHeader"
import PageFooter from "../components/chrome/pageFooter"
import SimpleGallery from "../components/simpleGallery"
import Breadcrumbs from "../components/chrome/breadcrumbs"

import Main from '../components/main';

// eslint-disable-next-line no-unused-vars
import Styles from '../styles/styles.scss'

const LayoutPost = ({ data }) => {
  const post = data.markdownRemark

  const Tags = post.frontmatter.tags?.map(tag => <li className="tag" key={tag.toLowerCase().replace(/ /g, '_')}><Link to={`/tags/${tag}`} className="tag__link">{tag}</Link></li>);

  let subTitle = null;
  if (post.frontmatter.subtitle !== '') {
    subTitle = <h2 className="post__subtitle">{post.frontmatter.subtitle}</h2>;
  }

  let simpleGalleryBlock = null;
  if (post.frontmatter.simpleGalleryImages !== null) {
    simpleGalleryBlock = post.frontmatter.simpleGalleryImages.map((gallery, index) =>
      <SimpleGallery
        key={index}
        gallery={gallery}
        useLightbox="true"
      />
    );
  }

  return (
    <div className="page page--post">
      <PageHeader headerText={post.frontmatter.title} />
      <SEO
        title={post.frontmatter.title}
        article={true}
        keywords={post.frontmatter.tags}
      />
      <Main>

        <Breadcrumbs title={post.frontmatter.title} path={post.fields.slug} />

        <div className="row">
          <div className="col--16">

            <article className="post">
              <header className="post__header">
                <div className="post__headings">
                  <h1 className="post__title">{post.frontmatter.title}</h1>
                  {subTitle}
                </div>
                <div className="post__meta">
                  <span className="post__date">First published: {post.frontmatter.datePublished}</span>
                </div>
              </header>

              <div className="post__body" dangerouslySetInnerHTML={{ __html: post.html }} />

              {simpleGalleryBlock}

              <Helmet>
                <script type="application/ld+json">
                  {`{
                  "@context": "http://schema.org",
                  "@type": "Article",
                  "author": "${data.site.siteMetadata.author}",
                  "name": "${post.frontmatter.title}",
                  "dateCreated": "${post.frontmatter.date}",
                  "dateModified": "${post.frontmatter.dateModified}",
                  "datePublished": "${post.frontmatter.datePublished}"
                }`}
                </script>
              </Helmet>

              <div className="post__footer">
                <dt className="reference__term">Filed under</dt> <dd className="reference__definition"><ul className="tags">{Tags}</ul></dd>
              </div>
            </article>

          </div>
        </div>
      </Main>
      <PageFooter />
    </div>
  )
}

export const query = graphql`query ($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    fileAbsolutePath
    html
    frontmatter {
      title
      subtitle
      date
      dateModified
      datePublished
      tags
      simpleGalleryImages {
        title
        galleryDescription
        images {
          thumb: file {
            childImageSharp {
              gatsbyImageData(
                width: 456
                height: 456
                transformOptions: {cropFocus: NORTH}
                layout: CONSTRAINED
              )
            }
          }
          full: file {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
              )
            }
          }
          alt
          description
          caption
        }
      }
    }
    fields {
      slug
    }
  } 
  site {
    id
    siteMetadata {
      author
      description
      title
    }
  }
}
`

export default LayoutPost